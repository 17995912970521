import './utils/jquery.utils';

import DecisionTree from './plugins/decision-tree.js';

import handleAnchorLinks from './plugins/anchor-link.js';
import handleFlosNavBar from './plugins/flos-local-nav.js';
import handleAttentionBanner from './plugins/attention-banner.js';
import handleBodyEvents from './plugins/body-events.js';
import handleEqualHeight from './plugins/equal-height.js';
import handleProcessBar from './plugins/process-bar.js';
import handleNavList from './plugins/nav-list.js';
import handleResponsiveTable from './plugins/responsive-table.js';
import handleStickyFooter from './plugins/sticky-footer.js';
import bootstrapDatepicker from './plugins/date-field/date-field';
import cookieHandler from './plugins/cookie-handler.js';

// application specific
import handleSummeryBar from './plugins/summary-bar.js';
import handlePriceBar from './plugins/price-bar.js';
import handleToggleSelect from './plugins/toggle-select.js';

function initCommonFunctionality(customOptions) {
    let options = jQuery.extend({}, customOptions);

    if (options.disableInit) {
        return;
    }

    let decisionTree = new DecisionTree();
    decisionTree.init();

    handleFlosNavBar();
    handleAnchorLinks();
    handleAttentionBanner();
    handleBodyEvents();
    handleEqualHeight();
    handleProcessBar();
    handleNavList();
    handleResponsiveTable();
    handleStickyFooter();
    // application specific
    handleSummeryBar();
    handlePriceBar();
    handleToggleSelect();
}

export default function (customOptions) {
    initCommonFunctionality(customOptions);

    window.$ = window.jQuery = jQuery;

    // Public API
    window.TD = {
        init: initCommonFunctionality,
        Plugins: {
            ResponsiveTable: {
                init: handleResponsiveTable, // used in car-mileage-receipt-app, topbiz-insurance-details-coverage-app, claimDetails, top-mpp, top-npe, topland-insurance-details-coverage-app,
            },
            ProcessBar: {
                init: handleProcessBar, // used in car-mileage-app, top-npe...
            },
            CookieHandler: cookieHandler, // used in top-mpp, pension-pages, alive-mpp-savings-app and flos scripts
            LocalNav: {
                init: (selector) => {
                    handleFlosNavBar(selector); // used on product pages in BrXm
                },
            },
            datepicker: bootstrapDatepicker, // used in ragnarok-contact-info-app, alive-mpp-change-investment-risiko-app...
            NavList: {
                init: handleNavList, // used on /forsikringer page in BrXm
            },
        },
    };
}
