export default class CookieHandler {
    static createCookie(name, value, days) {
        var date, expires, domainParts, domain, cookieString;

        if (days) {
            date = new Date();
            date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
            expires = '; expires=' + date.toUTCString();
        }

        domainParts = location.host.split('.');
        cookieString = name + '=' + value + expires + '; path=/';
        // If domain contains . - set the cookie on the top domain
        if (domainParts.length > 1) {
            domainParts.shift();
            domain = '.' + domainParts.join('.');
            cookieString += '; domain=' + domain;
        }
        document.cookie = cookieString;
    }

    static readCookie(name) {
        var nameEQ = name + '=',
            cookieArray = document.cookie.split(';'),
            cookie,
            iterator;

        for (iterator = 0; iterator < cookieArray.length; iterator++) {
            cookie = cookieArray[iterator];
            while (cookie.charAt(0) === ' ') {
                cookie = cookie.substring(1, cookie.length);
            }
            if (cookie.indexOf(nameEQ) === 0) {
                return cookie.substring(nameEQ.length, cookie.length);
            }
        }
        return null;
    }

    static eraseCookie(name) {
        this.createCookie(name, '', -1);
    }
}
