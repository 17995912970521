import Utils from '../utils/td.utils';

export default function handleAnchorLinks() {
    let hash, $hashTarget;

    if (location.hash && location.hash.indexOf('/') === -1) {
        hash = location.hash;
        $hashTarget = $('a[data-target="' + hash + '"]');

        if (!$hashTarget.length) {
            $hashTarget = $('a[href="' + hash + '"]');
        }

        $hashTarget.first().trigger('click');

        if ($hashTarget.is('[data-toggle]')) {
            setTimeout(function () {
                Utils.scrollToElement($hashTarget.get(0), true);
            }, 10);
        }

        $('option[value="' + hash + '"]')
            .parent()
            .val(hash);
    }
}
