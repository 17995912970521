export default function priceBar() {
    const newPrice = $('.new');
    const currentPriceDekstop = $('.desktop.current');
    const currentPriceMobile = $('.mobile.current');
    const arrow = $('.arrow');
    const linkArrow = $('.visible-xs, .top-right');
    const oldPriceEl = $('#oldPrice');

    const formattedCurrentPriceDesktop = Number(currentPriceDekstop.text()).toLocaleString('de-DE');
    const formattedCurrentPriceMobile = Number(currentPriceMobile.text()).toLocaleString('de-DE');

    function toggleArrow() {
        if (arrow.hasClass('down')) {
            arrow.removeClass('down');
            arrow.addClass('up');
        } else {
            arrow.removeClass('up');
            arrow.addClass('down');
        }
    }

    function countToNumber() {
        const countTo = newPrice.attr('data-count');

        $({ countNum: newPrice.text() }).animate(
            { countNum: countTo },
            {
                duration: 800,
                easing: 'linear',
                step: function () {
                    newPrice.text(Math.floor(this.countNum));
                },
                complete: function () {
                    newPrice.text(this.countNum.toLocaleString('de-DE'));
                },
            }
        );
    }

    oldPriceEl.on('show.bs.collapse', function () {
        linkArrow.addClass('a-disabled');
        toggleArrow();
    });
    oldPriceEl.on('hide.bs.collapse', function () {
        linkArrow.addClass('a-disabled');
        toggleArrow();
    });
    oldPriceEl.on('shown.bs.collapse', function () {
        linkArrow.removeClass('a-disabled');
    });
    oldPriceEl.on('hidden.bs.collapse', function () {
        linkArrow.removeClass('a-disabled');
    });

    currentPriceDekstop.text(formattedCurrentPriceDesktop);
    currentPriceMobile.text(formattedCurrentPriceMobile);

    countToNumber();
}
