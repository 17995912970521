import cookieHandler from './cookie-handler.js';

export default function handleAttentionBanner() {
    var dismissed = cookieHandler.readCookie('attention_dismissed'),
        dismissedArray = dismissed ? dismissed.split(',') : [];

    $('.AttentionBanner').each(function () {
        var $this = $(this);

        if ($.inArray($this.data('attention-name'), dismissedArray) === -1) {
            $this.removeClass('AttentionBanner--hidden');
        }
    });
    $('.AttentionBanner-close').on('click.close', function () {
        var $container = $(this).parents('.AttentionBanner'),
            cookie,
            cookieExistingValue = cookieHandler.readCookie('attention_dismissed'),
            cookieValue = encodeURIComponent($container.data('attention-name')),
            cookieExpireDate = new Date();

        $container.slideUp();

        if (cookieExistingValue !== null) {
            cookieValue = cookieExistingValue + ',' + cookieValue;
        }

        cookieExpireDate.setDate(cookieExpireDate.getDate() + 31); // 31 days.

        cookie = 'attention_dismissed=' + cookieValue + '; path=/; expires=' + cookieExpireDate.toUTCString();
        document.cookie = cookie;
    });
}
