export default function handleToggleSelect() {
    let $body = $('body');
    $body.on('click', '[data-toggle="select"]', function (e) {
        let $el = $(e.currentTarget),
            idOfTargetOption = $el.attr('href').substr(1),
            $targetOption = $('option#' + idOfTargetOption),
            $targetSelect = $targetOption.parents('select');

        e.preventDefault();

        // Only act if the target option wasn't already selected
        if (!$targetOption.is(':selected')) {
            $targetOption.prop('selected', true);
            $targetSelect.trigger('change');
        }
    });

    $body.on('change', 'select', function (e) {
        let $select = $(e.currentTarget),
            $options = $select.find('option'),
            $selectedOptions = $options.filter(':selected');

        // For all options, find any element with @data-toggle="select" that have an href equal to that of the option
        // ... and deselect them
        $options.each(function (index, el) {
            let idOfOption = $(el).attr('id');
            $('[data-toggle="select"][href="#' + idOfOption + '"]').removeClass('is-active');
        });

        // For all SELECTED options, find any element with @data-toggle="select" that have an href equal to that of the option
        // ... and select them
        $selectedOptions.each(function (index, el) {
            let idOfOption = $(el).attr('id');
            $('[data-toggle="select"][href="#' + idOfOption + '"]').addClass('is-active');
        });
    });
}
