export default function handleResponsiveTable(customSelector) {
    let selector = '.contenttable';
    if (typeof customSelector === 'string' && customSelector !== '') {
        selector = customSelector;
    } else if (typeof customSelector === 'object' && customSelector !== null) {
        selector = $(customSelector).find('.contenttable');
    }

    let hasClass = function (el, className) {
        return new RegExp('(^| )' + className + '( |$)', 'gi').test(el.className);
    };

    let addMobileLabels = function (tableSection, headerTexts) {
        var mobileColumnLabel;

        if (tableSection) {
            for (var rowIndex = 0; rowIndex < tableSection.rows.length; rowIndex++) {
                var row = tableSection.rows[rowIndex];

                for (var columnIndex = 0; columnIndex < row.cells.length; columnIndex++) {
                    var col = row.cells[columnIndex];

                    var isOmitted = hasClass(col, 'contenttable__ommit-mobile-th'),
                        isRowHeader = hasClass(row, 'rowheader');

                    if (headerTexts[columnIndex] && !isOmitted && !isRowHeader) {
                        mobileColumnLabel = document.createElement('span');
                        mobileColumnLabel.className = 'contenttable__mobile-th';
                        mobileColumnLabel.innerHTML = headerTexts[columnIndex];
                        col.insertBefore(mobileColumnLabel, col.firstChild);
                    }
                }
            }
        }
    };

    $('body').on('click', '[data-toggle="table-expand"]', function (e) {
        var selectorWithoutPeriod = selector.split('.')[1];

        e.preventDefault();

        $(e.currentTarget)
            .parents(selector + '__expander')
            .toggleClass(selectorWithoutPeriod + '__expander--expanded')
            .parents(selector)
            .find('.hide, [data-table-expand]')
            .attr('data-table-expand', true)
            .toggleClass('hide');
    });

    $(selector).each(function () {
        var table = $(this),
            headerTexts = [],
            headers = table.find('thead tr:last-child th'),
            tableBodies = table.find('tbody'),
            tablefoot = table.find('tfoot').get(0);

        if (table.data('isResponsivenessApplied') === 'true') {
            return;
        }

        for (var bodyIndex = 0; bodyIndex < tableBodies.length; bodyIndex++) {
            var body = tableBodies[bodyIndex];
            if (body.rows.length === 0) {
                return;
            }

            headers.each(function () {
                //  makes sure that we get all the inner html into the contenttable__mobile-th and not only text (to support info-dialog icon in mobile)
                var html = $(this).html().trim();
                headerTexts.push(html);
            });

            // Handle THEAD TH / TBODY TD mismatch (caused by colspan etc.).
            var headBodyCellDiff = body.rows[0].cells.length - headerTexts.length;
            if (headBodyCellDiff > 0) {
                for (var x = 0; x < headBodyCellDiff; x++) {
                    headerTexts.unshift('');
                }
            }

            table.data('isResponsivenessApplied', 'true');

            addMobileLabels(body, headerTexts);
        }

        addMobileLabels(tablefoot, headerTexts);
    });
}
