const Danish = {
    weekdays: {
        shorthand: ['Sø', 'Ma', 'Ti', 'On', 'To', 'Fr', 'Lø'],
        longhand: ['Søndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag'],
    },
    months: {
        shorthand: ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
        longhand: ['Januar', 'Februar', 'Marts', 'April', 'Maj', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'Movember', 'December'],
    },
    ordinal: function () {
        return '.';
    },
    firstDayOfWeek: 1,
    rangeSeparator: ' til ',
    weekAbbreviation: 'uge',
};

module.exports.Danish = Danish;
