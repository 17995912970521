export default class Utils {
    static scale() {
        return document.body.clientWidth / window.innerWidth;
    }

    static escapeRegExp(input) {
        return input.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
    }

    static executeMatchingSelectors(selectors) {
        for (var selector in selectors) {
            var match = document.querySelector(selector);

            if (match) {
                selectors[selector].call(match, match);
            }
        }
    }

    static throttle(fn, threshold, scope) {
        var last, deferTimer;

        threshold = threshold || 250;

        return function () {
            var context = scope || this,
                now = +new Date(),
                args = arguments;

            if (last && now < last + threshold) {
                // hold on to it
                clearTimeout(deferTimer);
                deferTimer = setTimeout(function () {
                    last = now;
                    fn.apply(context, args);
                }, threshold);
            } else {
                last = now;
                fn.apply(context, args);
            }
        };
    }

    /**
     * Finds a dom elements position on the screen
     * @param obj
     * @returns {{left: number, top: number}}
     */
    static findPos(obj) {
        let curleft = 0,
            curtop = 0,
            position = {};

        if (obj.offsetParent) {
            do {
                curleft += obj.offsetLeft;
                curtop += obj.offsetTop;
            } while ((obj = obj.offsetParent));
            position = { left: curleft, top: curtop };
        }
        return position;
    }

    /**
     * Scrolls the viewport to the given dom element
     * @param el
     * @param useStickyFix
     */
    static scrollToElement(el, useStickyFix) {
        let top = Utils.findPos(el).top;

        if (useStickyFix) {
            window.scrollTo(0, top);
        }

        setTimeout(function () {
            let globalMenu = document.getElementsByClassName('main-navbar'),
                processBar = document.getElementsByClassName('ProcessBar-container');

            let subtract = (globalMenu.length > 0 && globalMenu[0].clientHeight) + (processBar.length > 0 && processBar[0].clientHeight) + 10;

            top = Utils.findPos(el).top;
            window.scrollTo(0, top - subtract);
        }, 100);
    }

    static isGridFloated() {
        return window.matchMedia && this.gridFloatMQ().matches;
    }

    static gridFloatMQ() {
        return window.matchMedia && window.matchMedia('(max-width: 767px)');
    }

    /**
     * Check if the current browser is on the iOS platform (iPhone, iPod or iPad)
     * @returns {boolean}
     */
    static isIos() {
        return /iP(hone|od|ad)/.test(navigator.platform);
    }

    /**
     * Check if the current browser supports a css flexbox.
     * @returns {boolean}
     */
    static supportsFlexbox() {
        let testElement = document.createElement('div');
        return testElement.style.flex !== undefined && testElement.style.flexFlow !== undefined;
    }

    /**
     * Check if the current browser supports a given type for html input elements.
     * @param desiredType
     * @returns {boolean}
     */
    static supportsInputType(desiredType) {
        let testElement = document.createElement('input'),
            supported = false;

        testElement.setAttribute('type', 'date');
        if (testElement.type === desiredType) {
            supported = true;
        }
        testElement.value = 'Hello world';
        var helloWorldAccepted = testElement.value === 'Hello world';
        switch (desiredType) {
            case 'email':
            case 'url':
                if (!testElement.validationMessage) {
                    supported = false;
                }
                break;
            case 'color':
            case 'date':
            case 'datetime':
            case 'month':
            case 'number':
            case 'time':
            case 'week':
                if (helloWorldAccepted) {
                    supported = false;
                }
                break;
            default:
                console.log('Unknown input type: ' + desiredType);
        }

        return supported;
    }
}
