export default function handleFlosNavBar(selector) {
    if (typeof selector === 'undefined') {
        selector = 'main > section, main > .Hero, main > .flos-hero';
    }
    if (!document.querySelector('.flos-local-nav--fixed')) {
        return;
    }
    const updateLovalNav = (entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                // Find and deselect active anchor
                let activeAnchor = document.querySelector('.flos-local-nav--fixed .active');
                if (activeAnchor) {
                    document.querySelector('.flos-local-nav--fixed .active').classList.remove('active');
                }

                // add active / selected for current entry
                var id = entry.target.getAttribute('id');
                if (id) {
                    document.querySelector(`.flos-local-nav-container-desktop [href="#${id}"]`).classList.add('active');
                    document.querySelector(`.flos-local-nav-container-mobile select`).value = `#${id}`;
                }
            }
        });
    };

    const localNavObserver = new IntersectionObserver(updateLovalNav, {
        root: null,
        rootMargin: '0% 0% -80% 0%',
        threshold: 0,
    });

    let sections = [...document.querySelectorAll(selector)].filter((section) => section.hasAttribute('id')).reverse();

    const localNavLinks = [...document.querySelectorAll('.flos-local-nav--fixed a')].reverse();
    const localNavOptions = [...document.querySelectorAll('.flos-local-nav--fixed option')].reverse();

    sections.forEach((target, index) => {
        if (localNavLinks[index] || localNavOptions[index]) {
            if (localNavLinks[index]) {
                localNavLinks[index].setAttribute('href', '#' + target.id);
            }
            if (localNavOptions[index]) {
                localNavOptions[index].setAttribute('value', '#' + target.id);
            }
            // observe each section
            localNavObserver.observe(target);
        }
    });

    // add defaultValue to select field
    const localNavSelect = document.querySelector('.flos-local-nav--fixed select');
    if (localNavSelect && sections) {
        localNavSelect.setAttribute('defaultValue', '#' + sections[0].id);
    }
}
