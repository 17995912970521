export default function handleStickyFooter() {
    let ensureStickyFooter = function () {
        let windowHeight = $(window).outerHeight(),
            headerHeight = $('.cvi-header').outerHeight(),
            footerHeight = $('.site-footer').outerHeight(),
            targetHeight = windowHeight - headerHeight - footerHeight,
            $main = $('main');

        $main.css('min-height', '');

        if ($main.outerHeight() < targetHeight) {
            $main.css('min-height', targetHeight);
        }

        $('.site-footer').css('opacity', 1);
    };

    $(window).on('resize', ensureStickyFooter);
    ensureStickyFooter();
}
