import cookieHandler from './cookie-handler.js';
import Utils from '../utils/td.utils';

export default function handleBodyEvents() {
    $('body')
        .on('shown.bs.collapse hidden.bs.collapse', function () {
            $(window).trigger('heightChanged');
        })
        .on('shown.bs.collapse', function (evt) {
            var $target = $(evt.target).parent(),
                adjustScreen = function (scrolled) {
                    var topOffset = $target.offset().top - scrolled;
                    if (topOffset < 0) {
                        if (!$target.is('.decisiontree')) {
                            Utils.scrollToElement($target.get(0));
                        }
                    }
                };

            // Ensure there are only one .Box-adv.is-expandable open.
            if ($target.is('.Box--adv.is-expandable')) {
                $('.Box--adv.is-expandable').not($target).find('.collapse.in').collapse('hide');
                setTimeout(function () {
                    adjustScreen(document.documentElement.scrollTop || document.body.scrollTop);
                }, 400);
            } else {
                adjustScreen(document.documentElement.scrollTop || document.body.scrollTop);
            }
        })
        .on('click.chk', '.CheckBox.Box', function () {
            var $chk = $(this).prev();

            setTimeout(function () {
                $chk.blur();
            }, 1);
        });

    if (window.location.hostname.indexOf('hjemmearbejde') === -1) {
        var $navBarSegments = $('a', '.navbar-segments');
        $navBarSegments.each(function () {
            $(this).on('click', function () {
                cookieHandler.createCookie('site', $(this).data('segment'), 30);
            });
        });

        var site = cookieHandler.readCookie('site');

        if ($navBarSegments.length && site === 'Erhverv' && location.pathname === '/') {
            document.location.href = location.protocol + '//' + location.host + '/erhverv/';
        }
        if ($navBarSegments.length && site === 'Landbrug' && location.pathname === '/') {
            document.location.href = location.protocol + '//' + location.host + '/landbrug/';
        }
    }
}
