export default function handleSummeryBar() {
    let $processBarContainer = $('.ProcessBar-container'),
        $processBar = $processBarContainer.find('.ProcessBar'),
        $summaryBar = $processBar.find('.SummaryBar'),
        $summaryBarBar = $summaryBar.find('.SummaryBar-bar'),
        $summaryBarCollapse = $summaryBar.find('.SummaryBar-collapse'),
        $summaryBarCollapseInner = $summaryBarCollapse.find('.SummaryBar-collapse__inner'),
        isOpen = $summaryBarCollapse.hasClass('in');

    if ($processBarContainer.length === 0) {
        return;
    }

    function onCollapseShow() {
        isOpen = true;
        setTimeout(() => {
            var collapseHeight = parseInt($summaryBarCollapse.get(0).style.height, 10);

            $summaryBar.removeClass('SummaryBar--collapsed');
            $processBarContainer.css('height', $summaryBarBar.outerHeight() + collapseHeight);
        }, 0);
    }

    function onCollapseHide() {
        isOpen = false;
        $summaryBar.addClass('SummaryBar--collapsed');
        $processBarContainer.css('height', $summaryBarBar.outerHeight());
    }

    $summaryBarCollapse.on('show.bs.collapse', onCollapseShow);
    $summaryBarCollapse.on('hide.bs.collapse', onCollapseHide);

    $('body').on('change.height.SummaryBar', function () {
        if (isOpen) {
            $summaryBarCollapse.css('height', $summaryBarCollapseInner.outerHeight());
            onCollapseShow();
        }
    });
}
