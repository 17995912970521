import Utils from '../utils/td.utils';

export default function handleEqualHeight() {
    let eh = {
        apply: function (selector) {
            $(selector).each(function () {
                var $boxes = $(this).find('.Box').removeClass('forced-height').css('height', ''),
                    highest = 0;

                $boxes.each(function () {
                    var height = $(this).height();

                    if (height > highest) {
                        highest = height;
                    }
                });

                if (highest > 1) {
                    $boxes.addClass('forced-height').height(highest);
                }
            });

            return this.apply.bind(this, selector);
        },
    };

    if (!Utils.supportsFlexbox() && !Utils.isGridFloated()) {
        var equalHeightResize = eh.apply('.row--equal-sm, .row--equal-md, .row--equal-xs');

        $(window).on(
            'load.equalHeight resize.equalHeight heightChanged.equalHeight',
            Utils.throttle(function () {
                equalHeightResize();
            })
        );
    }
}
