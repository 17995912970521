(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory();
	else if(typeof define === 'function' && define.amd)
		define("@flos/react-ui", [], factory);
	else if(typeof exports === 'object')
		exports["@flos/react-ui"] = factory();
	else
		root["@flos/react-ui"] = factory();
})(self, () => {
return 