export default function handleNavList() {
    let navListItems = [...document.querySelectorAll('.NavList a')].filter((item) => {
        return document.getElementById(item.getAttribute('href'));
    });

    if (navListItems.length === 0) return;

    navListItems.forEach((item) => {
        item.addEventListener('click', function handleClick() {
            navListItems.forEach((item) => {
                item.classList.remove('icon-box--selected');
                document.getElementById(item.getAttribute('href')).classList.add('is-hidden');
            });

            item.classList.add('icon-box--selected');
            document.getElementById(item.getAttribute('href')).classList.remove('is-hidden');
        });
    });

    if (window.location.hash) {
        let activeElement = navListItems.find((item) => {
            return item.getAttribute('href') === window.location.hash;
        });
        if (activeElement) {
            activeElement.click();
            activeElement.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'center',
            });
        }
    }
}
