import Utils from '../utils/td.utils';

export default function handleProcessBar() {
    let $processBar = $('.ProcessBar');

    if (!$processBar.length) {
        return;
    }

    var $processBarContainer = $processBar.parent(),
        $mainNavbar = $('.main-navbar');

    if ($processBarContainer.children().length) {
        $processBarContainer.css('height', $processBarContainer.children().outerHeight());
    }

    $(window)
        .on(
            'scroll.processbar, resize.processbar',
            Utils.throttle(
                () => {
                    let top = $processBarContainer.position().top,
                        scrolled = (document.documentElement.scrollTop || document.body.scrollTop) + $mainNavbar.height(),
                        diff = top - scrolled;

                    $processBar.toggleClass('is-sticky', diff < 1);
                },
                100,
                this
            )
        )
        .trigger('scroll.processbar');
}
