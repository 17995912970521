export default class DecisionTree {
    constructor() {
        this.$decisiontreeContainer = $('.decisiontree');
        this.$contentContainer = $('#decisiontree-output');
    }

    init() {
        let self = this;

        if (self.$decisiontreeContainer.length === 0) {
            return;
        }

        self.$decisiontreeContainer.on('click.decisiontree change.decisiontree', 'a.Box, select.visible-xs', function (e) {
            e.preventDefault();
            self.handleEvent($(e.target || e.srcElement));
        });
    }

    handleEvent($clickedElement) {
        let self = this,
            id = $clickedElement.data('for-id') || $clickedElement.val(),
            contentUrl = $clickedElement.data('url') || $clickedElement.find(':selected').data('url');

        if (contentUrl) {
            $clickedElement.closest('.Section').find('.Box--selectable').removeClass('is-active');
            $clickedElement.addClass('is-active');
            self.renderContent(contentUrl);
            return;
        }

        self.hideContent();
        self.moveToNextLevel($clickedElement, id);
    }

    renderContent(contentUrl) {
        let self = this;

        $.get(contentUrl).done(function (data) {
            if (self.$contentContainer.is(':visible')) {
                self.$contentContainer.one('hidden.bs.collapse', function () {
                    self.$contentContainer.html(data).collapse('show');
                });
                self.$contentContainer.collapse('hide');
            } else {
                self.$contentContainer.html(data).collapse('show');
            }
        });
    }

    hideContent() {
        this.$contentContainer.collapse('hide');
    }

    moveToNextLevel($element, id) {
        var $parentSection = $element.closest('.Section');

        $parentSection.find('.Box--selectable').removeClass('is-active');

        $parentSection.removeClass('Section--alt').nextAll('.Section').addClass('hidden').find('.Box--selectable').removeClass('is-active');

        $element.addClass('is-active');

        $('#' + id)
            .removeClass('hidden')
            .addClass('Section--alt');
    }

    handleFormSubmit($element) {
        var self = this;
        $element.find('form').submit(function (e) {
            var postData = $(this).serializeArray(),
                formURL = $(this).attr('action');
            $.ajax({
                url: formURL,
                type: 'POST',
                data: postData,
                success: function (data, textStatus) {
                    console.log('form posted', data, textStatus);
                    $element.html(data);
                    self.handleFormSubmit($element);
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    console.log('form post failed', textStatus, errorThrown);
                },
            });
            e.preventDefault(); // stop default submit action
        });
    }
}
